<template>
  <div class="layout-wrapper layout-menu-lenze layout-topbar-lenze layout-menu-horizontal layout-menu-active">
    <div class="pages-body notfound-page flex flex-column">
      <div class="layout-topbar shadow-4">
        <div class="layout-topbar-left p-d-flex" routerLink="/login">
          <a class="layout-topbar-logo" href="/#/login">
            <img id="logo" src="layout/images/logo-light.svg" alt="autocbm-logo" style="height: 2.25rem">
          </a>
        </div>
      </div>

      <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
          <div class="pages-header px-3 py-1">
            <h2>{{ t('app.pages.not_found.header') }}</h2>
          </div>
          <div class="card mt-3 px-6">
            <img src="layout/images/pages/404.png" alt="" />
          </div>
          <div class="pages-detail pb-6">{{ t('app.pages.not_found.message') }}</div>
          <router-link to="/">
            <Button type="button" :label="t('app.pages.not_found.back')" class="p-button-text"></Button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup () {
    const messages = {
      de: {
        app: {
          pages: {
            not_found: {
              header: 'Nicht Gefunden',
              message: 'Die angeforderte Ressource ist nicht verfügbar!',
              back: 'Zurück'
            }
          }
        }
      },
      en: {
        app: {
          pages: {
            not_found: {
              header: 'NOT FOUND',
              message: 'Requested resource is not available!',
              back: 'Go back'
            }
          }
        }
      }
    }

    const { t } = useI18n({ useScope: 'global', messages })

    return { t }
  }
}
</script>
